function parseDataset(dataObj) {
  return Object.keys(dataObj).reduce((acc, key) => {
    try {
      acc[key] = JSON.parse(dataObj[key]);
    }
    catch (e) {
      acc[key] = dataObj[key];
    }
    return acc;
  }, {});
}

export default parseDataset;